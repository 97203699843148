import environment from '../../config/environment.json';
import { Capacitor } from '@capacitor/core';

class Geography {
	usStates = [];

	constructor() {
		this.loadUsStates();
	}

	loadUsStates() {
		this.usStates.push(new UsState('Alabama', 'AL'));
		this.usStates.push(new UsState('Alaska', 'AK'));
		this.usStates.push(new UsState('Arizona', 'AZ'));
		this.usStates.push(new UsState('Arkansas', 'AR'));
		this.usStates.push(new UsState('California', 'CA'));
		this.usStates.push(new UsState('Colorado', 'CO'));
		this.usStates.push(new UsState('Connecticut', 'CT'));
		this.usStates.push(new UsState('Washington DC', 'DC'));
		this.usStates.push(new UsState('Delaware', 'DE'));
		this.usStates.push(new UsState('Florida', 'FL'));
		this.usStates.push(new UsState('Georgia', 'GA'));
		this.usStates.push(new UsState('Hawaii', 'HI'));
		this.usStates.push(new UsState('Idaho', 'ID'));
		this.usStates.push(new UsState('Illinois', 'IL'));
		this.usStates.push(new UsState('Indiana', 'IN'));
		this.usStates.push(new UsState('Iowa', 'IA'));
		this.usStates.push(new UsState('Kansas', 'KS'));
		this.usStates.push(new UsState('Kentucky', 'KY'));
		this.usStates.push(new UsState('Louisiana', 'LA'));
		this.usStates.push(new UsState('Maine', 'ME'));
		this.usStates.push(new UsState('Maryland', 'MD'));
		this.usStates.push(new UsState('Massachusetts', 'MA'));
		this.usStates.push(new UsState('Michigan', 'MI'));
		this.usStates.push(new UsState('Minnesota', 'MN'));
		this.usStates.push(new UsState('Mississippi', 'MS'));
		this.usStates.push(new UsState('Missouri', 'MO'));
		this.usStates.push(new UsState('Montana', 'MT'));
		this.usStates.push(new UsState('Nebraska', 'NE'));
		this.usStates.push(new UsState('Nevada', 'NV'));
		this.usStates.push(new UsState('New Hampshire', 'NH'));
		this.usStates.push(new UsState('New Jersey', 'NJ'));
		this.usStates.push(new UsState('New Mexico', 'NM'));
		this.usStates.push(new UsState('New York', 'NY'));
		this.usStates.push(new UsState('North Carolina', 'NC'));
		this.usStates.push(new UsState('North Dakota', 'ND'));
		this.usStates.push(new UsState('Ohio', 'OH'));
		this.usStates.push(new UsState('Oklahoma', 'OK'));
		this.usStates.push(new UsState('Oregon', 'OR'));
		this.usStates.push(new UsState('Pennsylvania', 'PA'));
		this.usStates.push(new UsState('Rhode Island', 'RI'));
		this.usStates.push(new UsState('South Carolina', 'SC'));
		this.usStates.push(new UsState('South Dakota', 'SD'));
		this.usStates.push(new UsState('Tennessee', 'TN'));
		this.usStates.push(new UsState('Texas', 'TX'));
		this.usStates.push(new UsState('Utah', 'UT'));
		this.usStates.push(new UsState('Vermont', 'VT'));
		this.usStates.push(new UsState('Virginia', 'VA'));
		this.usStates.push(new UsState('Washington', 'WA'));
		this.usStates.push(new UsState('West Virginia', 'WV'));
		this.usStates.push(new UsState('Wisconsin', 'WI'));
		this.usStates.push(new UsState('Wyoming', 'WY'));
	}
}
class UsState {
	name = '';
	abbr = '';
	constructor(name, abbr){
		this.name = name;
		this.abbr = abbr;
	}
}

class EventKeys {
    loginAuthenticationUpdated = 'lpfn.login-authentication-updated';
    login = {
        timedOut: 'lpfn.login.timed-out',
    };
    memberUpdated = 'lpfn.member-updated';
	searchAndSelectMemberSelected = 'lpfn.search-and-select-member-selected';
    uiWidgetUpdated = 'lpfn.content.uiwidget.updated';
    reloadUiWidget = 'lpfn.content.reload-uiwidget';
    openContentEditor = 'lpfn.content.open-editor';
    tasks = {
        updated: 'lpfn.tasks.updated',
    };
    messages = {
        updated: 'lpfn.messages.updated',
    };
    site = {
        openLightbox: 'lpfn.site.open-lightbox',
        openProfile: 'lpfn.show-member-profile-popup',
        openPolicy: 'lpfn.show-policy-popup',
        openLead: 'lpfn.show-lead-popup',
        leadClosed: 'lpfn.lead-popup-closed',
        openRightPanel: 'lpfn.site.open-right-panel',
        modeChanged: 'lpfn.site.mode-changed',
        setPageTitle: 'lpfn.site.set-page-title',
        hideRegistrationLinks: 'lpfn.site.hide-registration-links',
    };
    menu = {
        changed: 'lpfn.menu.changed',
        reset: 'lpfn.menu.reset',
        updated: 'lpfn.menu.updated',
    };
    navigation = {
        changing: 'lpfn.navigation.changing',
        changed: 'lpfn.navigation.changed',
    };
    dashboard = {
        updated: 'lpfn.dashboard.updated',
        syncTimePeriod: 'lpfn.dashboard.syncTimePeriod',
        builderDetached: 'lpfn.dashboard.builderDetached',
    };
    newClientVersion = 'lpfn.new-client-version';
    keyboard = {
        opened: 'lpfn.keyboard.opened',
        closed: 'lpfn.keyboard.closed',
    };
    toDos = {
        updated: 'lpfn.todos.updated',
        todayUpdated: 'lpfn.todos.todayUpdated',
        toDoUpdated: (aboutId) => { return `lpfn-todos.toDoUpdated-${aboutId}`; },
    };
    notes = {
        updated: 'lpfn.notes.updated',
    };
    onboarding = {
        completed: 'lpfn.onboarding.completed',
    };
    agent = {
        profileImageUpdated: 'lpfn.agent.profile-image-updated',
        profileUpdated: 'lpfn.agent.profile-updated',
        configUpdated: 'lpfn.agent.config-updated',
    };
    lead = {
        updated: 'lpfn.lead.updated',
        profileUpdated: 'lpfn.lead.profile-updated',
        tabHeader: 'lpfn.lead.tab-header',
        changeScriptType: 'lpfn.lead.change-script-type',
        changeScriptPerson: 'lpfn.lead.change-script-person',
        deleted: 'lpfn.lead.deleted',
    };
    mentor = {
        assigned: 'lpfn.mentor.assigned',
        removed: 'lpfn.mentor.removed',
    };
    fileFor = {
        updated: (forId) => { return `lpfn.file-for.updated-${forId}`; }
    };
}

class RegEx {
    strongPassword = /^.*(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()<>{}|\? "]).*$/;
    isNumber = /\d/g;
    zipCode = /\d{5}(-\d{4})?/;
    phoneNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    slug = /^[0-9A-Za-z\-]+$/;
    ssn = /^\d{3}-?\d{2}-?\d{4}$/;
    guid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    email = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
}

class Helpers {
    uniqueId() {
        var idstr = String.fromCharCode(Math.floor((Math.random()*25)+65));
        do {                
            var ascicode = Math.floor((Math.random()*42)+48);
            if (ascicode < 58 || ascicode > 64){
                idstr += String.fromCharCode(ascicode);
            }                
        } while (idstr.length<32);

        return (idstr);
    }

    camelize(word) {
        return `${word.substr(0,1).toLowerCase()}${word.substr(1)}`;
    }

    replaceAll(str, find, replace) {
        if (str.replaceAll) return str.replaceAll(find, replace);
        // not new browser, use old regex method
        return str.replace(new RegExp(find, 'g'), replace);
    }

    replaceVariables(security, value, urlEncode = false) {
        const baseUrl = siteUrlBase();
        return value
            .replace(/{{firstName}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.firstName) : security.agent.firstName : '')
            .replace(/{{lastName}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.lastName) : security.agent.lastName : '')
            .replace(/{{email}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.email) : security.agent.email : '')
            .replace(/{{cell}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.cell) : security.agent.cell : '')
            .replace(/{{agentID}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.agentID) : security.agent.agentID : '')
            .replace(/{{agentIDNoDash}}/g, security.agent && security.agent.agentID ? urlEncode ? encodeURIComponent(security.agent.agentID.replace('-', '')) : security.agent.agentID.replace('-', '') : '')
            .replace(/{{signUpLink}}/g, urlEncode ? encodeURIComponent(security.signUpLink()) : security.signUpLink())
            .replace(/{{directUplineFirstName}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.directUplineFirstName) : security.agent.directUplineFirstName : '')
            .replace(/{{directUplineLastName}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.directUplineLastName) : security.agent.directUplineLastName : '')
            .replace(/{{directUplineEmail}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.directUplineEmail) : security.agent.directUplineEmail : '')
            .replace(/{{directUplineCell}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.directUplineCell) : security.agent.directUplineCell : '')
            .replace(/{{agencyFirstName}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.agencyFirstName) : security.agent.agencyFirstName : '')
            .replace(/{{agencyLastName}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.agencyLastName) : security.agent.agencyLastName : '')
            .replace(/{{agencyEmail}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.agencyEmail) : security.agent.agencyEmail : '')
            .replace(/{{agencyCell}}/g, security.agent ? urlEncode ? encodeURIComponent(security.agent.agencyCell) : security.agent.agencyCell : '')
            .replace(/{{agencyAgentID}}/g, security.agent && security.agent.agencyAgentID ? urlEncode ? encodeURIComponent(security.agent.agencyAgentID) : security.agent.agencyAgentID : '')
            .replace(/{{agencyAgentIDNoDash}}/g, security.agent && security.agent.agencyAgentID ? urlEncode ? encodeURIComponent(security.agent.agencyAgentID.replace('-', '')) : security.agent.agencyAgentID.replace('-', '') : '')
            .replace(/{{siteUrl}}/g, urlEncode ? encodeURIComponent(baseUrl) : baseUrl)
        ;
    }

    formatNumber(value, decimals, removeInsignificantDigits = false) {
        try {
            if (value === null || value === undefined) return '-';
            if (isNaN(value)) return value;
            if (decimals === undefined || decimals === null) decimals = 0;
            const roundedValue = this.round(value, decimals);
            if (isNaN(roundedValue)) return roundedValue;
            let formattedValue = roundedValue.toLocaleString();
            let decimalIndex = formattedValue.indexOf('.');
            if (decimals === 2 && !removeInsignificantDigits) {
                if (decimalIndex < 0) formattedValue += '.00';
                else if (decimalIndex === formattedValue.length - 2) formattedValue += '0';
                else if (decimalIndex === formattedValue.length - 1) formattedValue += '00';
            }
            return formattedValue;
        } catch (err) {
            console.log(err);
            return value;
        }
    }
    
    formatMoney(number, decimals = 2) {
        const decPlaces = decimals;
        const decSep = '.';
        const thouSep = ',';
        let sign = number < 0 ? '-' : '';
        let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces), 10));
        let j = i.length;
        j = j > 3 ? j % 3 : 0;
    
        return sign + (j ? i.substr(0, j) + thouSep : '') + i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) + (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : '');
    }
    
    round(num, decimals = 2) {
        try {
            const rounded = +(Math.round(num + `e+${decimals}`)  + `e-${decimals}`);
            return rounded;
        } catch (err) {
            return num;
        }
    }

    keyify(str) {
        return str.replace(/\s/g, '-').toLowerCase();
    }

    encodeJsProperty(value) {
        if (!value) return value;
        return value.replace(/'/g, '{APOS}');
    }

    decodeJsProperty(value) {
        if (!value) return value;
        return value.replace(/{APOS}/g, '\'');
    }

    contrastingFontColor(hexcolor) {
        try {
            // If a leading # is provided, remove it
            if (hexcolor.slice(0, 1) === '#') hexcolor = hexcolor.slice(1);
            // If a three-character hexcode, make six-character
            if (hexcolor.length === 3) {
                hexcolor = hexcolor.split('').map((hex) => {
                    return hex + hex;
                }).join('');
            }
            // Convert to RGB value
            let r = parseInt(hexcolor.substr(0, 2), 16);
            let g = parseInt(hexcolor.substr(2, 2), 16);
            let b = parseInt(hexcolor.substr(4, 2), 16);
            // Get YIQ ratio
            let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
            // Check contrast
            return (yiq >= 128) ? '#000000' : '#ffffff';
        } catch (err) {
            return '#ffffff';
        }
    }

    stringToColor(str) {
        let hash = 0;
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let color = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            color += value.toString(16).padStart(2, '0')
        }
        const fontColor = this.contrastingFontColor(color);
        return {
            backgroundColor: color,
            fontColor,
        };
    }
}

class FlexGrid {

    sizes = {
        xxsmall: 'xxs',
        xxs: 'xxs',
        xsmall: 'xs',
        xs: 'xs',
        small: 'sm',
        sm: 'sm',
        medium: 'md',
        md: 'md',
        large: 'lg',
        lg: 'lg',
        xlarge: 'xl',
        xl: 'xl'
    };

    types = {
        string: 'string',
        number: 'number',
        money: 'money',
        date: 'date',
        bool: 'bool',
        html: 'html',
        action: 'action',
        member: 'member'
    };

    column(key, size, name, type, metadata) {
        return {
            key: key,
            size: size,
            name: name,
            type: type,
            metadata: metadata
        };
    }

    cellData(key, value, cssClass) {
        return {
            key: key,
            value: value,
            cssClass: cssClass
        };
    }
}


var c = {
	Geography: new Geography(),
    EventKeys: new EventKeys(),
    RegEx: new RegEx(),
    Helpers: new Helpers(),
    FlexGrid: new FlexGrid()
};

function isVideoUrl(linkSrc) {
    if (!linkSrc) return false;
    if (linkSrc.indexOf('vimeo.com') >= 0) return true;
    if (linkSrc.indexOf('youtu.be') >= 0) return true;
    if (linkSrc.indexOf('.mp4') >= 0) return true;
    return false;
}

function openVideo(src, ea, fullHeight = false) {
    let videoSrc;
    if (src.indexOf('https://vimeo.com/') === 0) {
        const startIndex = 'https://vimeo.com/'.length;
        const nextSlash = src.indexOf('/', startIndex + 1);
        const vimeoId = src.substr(startIndex, nextSlash - startIndex);
        videoSrc = `https://player.vimeo.com/video/${encodeURIComponent(vimeoId)}?autoplay=1`;
    } else if (src.indexOf('https://www.vimeo.com/') === 0) {
        const startIndex = 'https://www.vimeo.com/'.length;
        const nextSlash = src.indexOf('/', startIndex + 1);
        const vimeoId = src.substr(startIndex, nextSlash - startIndex);
        videoSrc = `https://player.vimeo.com/video/${encodeURIComponent(vimeoId)}?autoplay=1`;
    } else if (src.indexOf('https://youtu.be/') === 0) {
        const startIndex = 'https://youtu.be/'.length;
        const youTubeId = src.substr(startIndex);
        videoSrc = `https://www.youtube.com/embed/${encodeURIComponent(youTubeId)}`;
    } else {
        // try to play the video as linked
        videoSrc = src;
    }
    ea.publish(c.EventKeys.site.openLightbox, { embedSrc: videoSrc, fullHeight });
}

function getQsValue(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function siteUrlBase() {
    try {
        if (Capacitor.isNativePlatform) return environment.url;
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        if (environment.url.toLowerCase() === baseUrl.toLowerCase()) return environment.url;
        return baseUrl;
    } catch (err) {
        console.log(err);
        return environment.url;
    }
}

function apiUrlBase() {
    try {
        if (Capacitor.isNativePlatform) return environment.api;
        let baseUrl = window.location.origin;
        if (environment.url.toLowerCase() === baseUrl.toLowerCase()) return environment.api;
        return baseUrl;
    } catch (err) {
        console.log(err);
        return environment.api;
    }
}

class SocialMedia {
    link(key) {
        switch (key) {
            case 'twitter': return 'https://x.com/';
            case 'linkedin': return 'https://linkedin.com/in/';
            case 'facebook': return 'https://facebook.com/';
            case 'instagram': return 'https://www.instagram.com/';
            case 'snapchat': return 'https://www.snapchat.com/add/';
            case 'whatsapp': return 'https://wa.me/';
            case 'tiktok': return 'https://tiktok.com/';
        }
    }
    
    linkStarter(socialMediaKey) {
        let link = this.link(socialMediaKey);
        if (link && link !== socialMediaKey) return link;
        return '';
    }
    
    updateLink(socialMedia) {
        socialMedia.link = `${this.link(socialMedia.key)}${socialMedia.value}`;
    }
}

const socialMedia = new SocialMedia();

/**
 * Event listeners created with arrow functions can't be removed
 * Use this utility to add and remove event handlers to any scope
 *
 * let handler = eventListener(window, 'resize', () => { console.log(this); });
 * handler.dispose();
 *
 * @export
 * @param {any} scope
 * @param {String} type - the type of event to listen for
 * @param {Function} handler - can be () => { do something }
 * @param {boolean} [capture=false]
 * @returns {Function} to remove the event listener
 */
function eventListener(scope, type, handler, capture = false) {
    scope.addEventListener(type, handler, capture);
    return {
        dispose: () => {
            scope.removeEventListener(type, handler, capture);
        }
    };
}

function distinctArray(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
}

function initializeUiWidgetHtml(value, security) {
    let html = c.Helpers.replaceAll(value, 'href="api/file/', `href="${environment.static}/api/file/`);
    html = c.Helpers.replaceAll(html, 'href="/file/', `href="${environment.static}/file/`);
    html = c.Helpers.replaceAll(html, 'src="api/file/', `src="${environment.static}/api/file/`);
    html = c.Helpers.replaceAll(html, 'src="/file/', `src="${environment.static}/file/`);
    if (security) html = c.Helpers.replaceVariables(security, html);
    return html;
}


export {
    c,
    isVideoUrl,
    openVideo,
    getQsValue,
    siteUrlBase,
    apiUrlBase,
    socialMedia,
    eventListener,
    distinctArray,
    initializeUiWidgetHtml,
};

// eslint-disable-next-line no-extend-native
Number.prototype.formatMoney = function(cee, d, t) {
    //return cee.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    let n = this;
    // eslint-disable-next-line no-cond-assign
    cee = isNaN(cee = Math.abs(cee)) ? 2 : cee;
    d = d === undefined ? '.' : d;
    t = t === undefined ? ',' : t;
    let s = n < 0 ? '-' : '';
    // eslint-disable-next-line radix
    let i = parseInt(n = Math.abs(+n || 0).toFixed(cee)) + '';
    // eslint-disable-next-line no-cond-assign
    let j = i.length;
    j = j > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (cee ? d + Math.abs(n - i).toFixed(cee).slice(2) : '');
};

String.prototype.datePickerDate = function() {
    let value = this;
    if (!value) return null;
    return new Date(value).toISOString().substring(0, 10);
}
